<template>
    <section class="section section--head  section--details-bg">
        <div class="container">
            <div class="article">
                <div class="row" >
                    <div class="col-12 col-xl-9" v-for="publish in content" :key="publish.id">
                        <div class="row">
                            <div class="col-12">
                                <video  class="plyr" style="margin-right: 0px;width: 100%;" controls="" controlsList="nodownload">
                                    <source :src="publish.source" type="Video/mp4" size="720">
                                </video>
                                <br>
                                <div class="article__content">
                                    <ul class="list" style="margin-top: 0px;">
                                    <!-- <li><h3>{{ publish.title_ar }}</h3></li>
                                    <li><p> {{ publish.desc_ar }} </p></li>
                                    <li><h6>{{ publish.year }}</h6></li> -->
                                    <!-- <li><h6>1 h 42 min</h6></li>-->
                                    </ul>
                                </div> 
                            </div>
                        </div>
                        <div class="row" style=" padding: 0px 5px; margin-bottom: 8px;">
                            <div class="col-12">
                                <div class="comments__item">
									<div class="comments__autor">
										<!-- <img class="comments__avatar" src="assets/img/avatar.svg" style="width: 50px;height: 50px;" alt=""> -->
										<span class="comments__name" style="color: #046162;margin-right: 5px;"><h4>{{ publish.title_ar }}</h4></span>
										<span class="comments__time" style="color: #046162;margin-right: 5px;" ><h6>{{ publish.year }}</h6></span>
									</div>
									<p class="comments__text" style="color: rgb(4, 97, 98);font-family: 'Cairo', sans-serif;" >{{ publish.desc_ar }} </p>
								</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-3" style="margin-top: 40px;">
                        <div class="sidebar sidebar--mt" style="margin-top: 2px;">
                            <div class="row row--grid">
                                <div class="col-12" v-for="publish in getCatByArtistID" :key="publish.id">
                                    <div class="row" style="background: rgba(131, 137, 159, 0.07) none repeat scroll 0% 0%; padding: 0px 5px; margin-bottom: 8px;">
                                        <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="padding-right: 0px;"> 
                                            <div class="card" style="background-image: linear-gradient(0deg, rgba(0, 66, 105, 0) 0px, rgba(113, 198, 247, 0)); border-radius: 0px; box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px, rgba(0, 0, 0, 0) 0px 15px 20px; margin-top: 0px;height: auto;width: 145px;display: flex;position: relative;">
                                                <a @click="getFeed(publish)" class="card__cover" style="height: auto;">
                                                    <img :src="publish.img" alt="">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="text-align: right;">
                                            <a @click="getFeed(publish)">
                                                <ul class="">
                                                    <li><h5 class="card__title" style="color: rgb(4, 97, 98); margin-top: 13px;">{{ publish.title_ar }}</h5></li>
                                                    <li><h6 style="color: #046162;">{{ publish.desc_ar }}</h6></li>
                                                    <li ><h6  style="color: #046162;">{{ publish.name_ar }}</h6></li>
                                                    <li style="color: #046162;">{{ publish.year }}</li>
                                                </ul>   
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" v-for="publish in getCatByID" :key="publish.id">
                                    <div class="row" style="background: rgba(131, 137, 159, 0.07) none repeat scroll 0% 0%; padding: 0px 5px; margin-bottom: 8px;">
                                        <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="padding-right: 0px;"> 
                                            <div class="card" style="background-image: linear-gradient(0deg, rgba(0, 66, 105, 0) 0px, rgba(113, 198, 247, 0)); border-radius: 0px; box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px, rgba(0, 0, 0, 0) 0px 15px 20px; margin-top: 0px;height: auto;width: 145px;display: flex;position: relative;">
                                                <a @click="getFeed(publish)" class="card__cover" style="height: auto;">
                                                    <img :src="publish.img" alt="">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="text-align: right;">
                                            <a @click="getFeed(publish)">
                                                <ul class="">
                                                    <li><h5 class="card__title" style="color: rgb(4, 97, 98); margin-top: 13px;">{{ publish.title_ar }}</h5></li>
                                                    <li><h6 style="color: #046162;">{{ publish.desc_ar }}</h6></li>
                                                    <li ><h6  style="color: #046162;">{{ publish.name_ar }}</h6></li>
                                                    <li style="color: #046162;">{{ publish.year }}</li>
                                                </ul>   
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <!-- end details -->
        <!-- <div class="catalog2 catalog--page">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <ul class="title"><li style="margin-left: 30px;margin-right: 10px;"><h6 > مقترحات </h6></li><li style="margin-left: 30px;">-</li><li style="margin-left: 30px;"><h6 >الاكثر مشاهدة </h6></li></ul>
                    </div>
                    <div class="col-12">
                        <div class="row row--grid">
                            <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in getCatByID" :key="publish.id">
                                <div class="card">
                                    <a @click="getFeed(publish)" class="card__cover">
                                        <img :src="publish.img" alt="">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                    </a>
                                    <h3 class="card__title"><a @click="getFeed(publish)">{{ publish.title_ar }}</a></h3>
                                    <ul class="card__list">
                                        <li>تجريب</li>
                                        <li>صحة</li>
                                        <li>{{ publish.year }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>		
            </div>

        </div> -->
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useCookie } from 'vue-cookie-next'
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
export default {
  name: 'Contents',
    props: {
       Content_id: String,
    },
    async setup(props) {
      const router = useRouter();
      const cookie = useCookie()
      const content = ref([]);
      const getCatByID = ref([]);
      const getCatByArtistID = ref([]);
        try {
          await HTTP.get(`ContentById.php?CID=`+props.Content_id).then((res) => {
            content.value = res.data.Content;
          });
        } catch (err) {
            console.log(err);
        }

        try {
          await HTTP.get(`GetContentByCategory.php?cat_id=`+cookie.getCookie("cat_id")+`&LIMIT=12&OFFSET=0`).then((res) => {
            getCatByID.value = res.data.Content; 
          });
        } catch (err) {
          console.log(err);
        }
        try {
          await HTTP.get(`GetAllArtistsContent.php?ID=`+cookie.getCookie("artist_id")).then((res) => {
            getCatByArtistID.value = res.data.ArtistsContent; 
          });
        } catch (err) {
          console.log(err);
        }

        const getFeed = (publish) => {
          cookie.setCookie('cat_id', publish.cat_id)
          cookie.setCookie('artist_id', publish.cp_id)
          if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
              router.push({ name: "Content", params: { Content_id: publish.id } });
          } else {
              cookie.setCookie('id', publish.id);
              router.push({ name: "Login" });
          }
        };

        return { content, getCatByID,getCatByArtistID, getFeed };
      
  },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
.title {
    font-family: 'Cairo', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
    transition: 0.5s;
    display: block;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    word-wrap: break-word;
    background-color: #042f72;
background-image: linear-gradient(62deg, #047072 0%, #009ac9 100%);
    padding: 8px 5px 5px 5px;
    border-radius: 12px;


}
.catalog2 {
    position: relative;
    padding: 5px 0 25px;
    padding-top: calc(50vh - 90%);
    padding-right: 0px;
    padding-bottom: 25px;
    padding-left: 0px;
    overflow: hidden;
    padding-bottom: calc(50vh - 60%);
}
.catalog__paginator-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px auto 0;
    width: 100%;
    height: 50px;
    border-radius: 16px;
    background-color: #151f30;
    background-color: #047072;
    background-image: linear-gradient(62deg, #047072 0%, #00c9c9 100%);
}
</style>